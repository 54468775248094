.library-button {
  display: none !important;
}

.top-right-btn {
  display: flex;
  gap: 10px;
}

.errorToast {
  --background: #ffffff;
  --color: #ff4a3d;
}

.successToast {
  --background: #ffffff;
  --color: #29e009; 
}

.layer-ui__wrapper__footer-right {
  display: none !important;
}

.drawing-list-image { 
  width: 100%;
  aspect-ratio: auto 2 / 1;
  overflow: hidden;
  position: relative;
}

.drawing-list-image > img {
  width: 100%;
  height: 100%;
  object-fit:cover;
}

.drawing-list-image-avatar {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
}

.drawing-list-image-avatar > * {
  background: #FFF;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
  height: 40px;
  width: 40px;
}

.drawing-list-image-avatar > :last-child {
  margin-left: -10px;
}

.drawing-card-header {
  padding: 10px 16px;
}

.drawing-card-header > h3 {
  margin:0;
}

.drawing-card-header > h5 {
  margin:0;
  color: #ffffff;
}

.drawing-card-header > h6 {
  margin:0;
}

.drawing-card-content-chip {
  margin: 5px -5px;
}

.dropdown-menu-item {
  height: auto !important;
  padding: 10px 0 !important;
}

.dropdown-menu-item__text {
  padding-right: 10px;
  font-size: 1rem !important;
  display: flex;
  gap: 5px;
}
ion-datetime-button::part(native) {
  background: #874289;
  color: white;
}

.list-inset {
  margin: 16px 0 !important;
}

@media (prefers-color-scheme: dark) {
  ion-datetime {
    --background: black;
    --background-rgb: 0, 0, 0;
  }
}

.task-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
