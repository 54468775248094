.my-header {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  padding: 10px 25px;
}

.my-header-title {
  font-size: 18px;
}

.my-header-subTitle {
  font-size: 14px;
}

.my-header-setting {
  margin-left: auto;
}
